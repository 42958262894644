<template>
    <CHeader
      fixed
      with-subheader
      light
      class="text-white"
      style="
        background-color: #6a93cb;
        background-image: linear-gradient(
          315deg,
          rgb(125 158 204) 0%,
          rgb(26 53 94) 74%
        );
      "
    >
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
      </CHeaderBrand>
      <CHeaderNav class="d-md-down-none mr-auto">
        <CHeaderNavItem class="px-3">
        </CHeaderNavItem>
      </CHeaderNav>
      <CHeaderNav class="mr-5">
        <TheHeaderDropdownAccnt />
      </CHeaderNav>
    </CHeader>
  </template>
  
  <script>
  import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
  
  export default {
    name: "TheHeaderMenu",
    components: {
      TheHeaderDropdownAccnt,
    },
  };
  </script>
  