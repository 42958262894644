<template>
  <div class="c-app" style="background-image: url(/img/background-batik-putih.png);">
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>
  
<script>
  import { mapGetters } from "vuex";
  import TheHeader from "./TheHeaderMenu";
  export default {
    name: "TheContainerMenu",
    components: {
      TheHeader,
    },
    mounted(){
        this.loadMenu();
    },
    computed: {
        ...mapGetters("auth", {
            user: "user",
        }),
    },
    methods:{
        loadMenu(){
            if (this.user.role) {
                if (this.user.role.superadmin == true || this.user.role.superadmin == 1) {
                  this.$router.push({ name: "Dashboard" });
                } else {
                  console.log(this.user.role.role_name)
                  if (this.user.role.role_name != 'Pengusul') {
                    this.$router.push({ name: "Dashboard" });
                  } else {
                    this.$router.push({ name: "Home2" });
                  }
                }
            } else {
                this.$router.push({ name: "Dashboard" });
            }
        }
      }
    };
  </script>
  
  <style scoped>
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  </style>
  